/** This is the narrow list items used in context menus and inline menus */
import React, { MouseEventHandler } from "react";
import { classNames } from "../utils/classNames";
import { UnstyledButtonBase, UnstyledButtonBaseProps } from "../Button";
import { Icon, IconProps } from "@phosphor-icons/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useRouter } from "next/router";
import { Keys } from "react-hotkeys-hook/dist/types";
import KeyboardShortcut from "../KeyboardShortcut";

export type ContextMenuListItemProps = {
  children: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  shortcut?: Keys; // See https://react-hotkeys-hook.vercel.app/ for syntax of key combinations.
  className?: string;
  size?: 28 | 32;
  // Color of icon when user clicks the option
  activeTextColorClassName?: "text-oldblue-600" | "text-oldgray-600";
  IconLeft?:
    | ((props: React.ComponentProps<"svg">) => JSX.Element)
    | {
        Icon: Icon;
        iconProps?: IconProps;
      };
  IconRight?:
    | ((props: React.ComponentProps<"svg">) => JSX.Element)
    | {
        Icon: Icon;
        iconProps?: IconProps;
      };
};

export const ContextMenuListItem = React.forwardRef(function ContextMenuListItemComponent(
  props: ContextMenuListItemProps & UnstyledButtonBaseProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  // TODO: there's more here to design. See
  // https://www.figma.com/file/Q02N2bZ2LehnCKADSoNXfH/Humanloop-%E2%80%94-Design-Library?node-id=219%3A3159
  const {
    active,
    children,
    disabled,
    onClick,
    className,
    size = 28,
    IconLeft: _IconLeft,
    IconRight: _IconRight,
    shortcut,
    ...rest
  } = props;

  const sizeClasses = size === 28 ? "text-11-16 py-6" : "text-12-16 py-8";
  const iconSizeClasses = size === 28 ? "h-14 w-14" : "h-[18px] w-[18px]";

  const IconLeft = props.IconLeft && "Icon" in props.IconLeft ? props.IconLeft.Icon : props.IconLeft;
  let activeColorClassName;
  switch (props.activeTextColorClassName) {
    case "text-oldblue-600":
      activeColorClassName = "listitemgroup-active:text-oldblue-600";
      break;
    case "text-oldgray-600":
      activeColorClassName = "listitemgroup-active:text-oldgray-600";
      break;
    default:
      activeColorClassName = "listitemgroup-active:text-oldblue-600";
  }

  const iconLeftProps = props.IconLeft && "iconProps" in props.IconLeft ? props.IconLeft.iconProps : undefined;

  const IconRight = props.IconRight && "Icon" in props.IconRight ? props.IconRight.Icon : props.IconRight;
  const iconRightProps = props.IconRight && "iconProps" in props.IconRight ? props.IconRight.iconProps : undefined;
  const enabled = Boolean(shortcut) && !props.disabled;
  const router = useRouter();

  useHotkeys(
    shortcut ?? "",
    (event) => {
      // TODO: bit of hack using a mouse handler as a keyboard handler, potentially we should generalise to onSelect
      props.onClick && props.onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);
      props.href && router.push(props.href);
    },
    { enabled: enabled, keydown: true, keyup: true },
    [shortcut, props.href, props.onClick],
  );

  return (
    <UnstyledButtonBase
      ref={ref}
      {...rest}
      onClick={
        disabled
          ? undefined
          : (onClick as MouseEventHandler<HTMLButtonElement> & ((event: { preventDefault: Function }) => void))
      }
      className={classNames(
        sizeClasses,
        "listitemgroup flex w-full cursor-pointer select-none items-center justify-between gap-8 px-12 focus:bg-oldgray-200 focus:outline-none hover:active:bg-oldgray-300",
        "data-[state=open]:bg-oldgray-200",
        // Quick, cheap disabled styling. May want to revisit.
        disabled ? "cursor-not-allowed opacity-50 pointer-events-none" : active ? "bg-oldgray-200" : "hover:bg-oldgray-200",
        className,
      )}
    >
      {IconLeft && (
        <IconLeft
          className={classNames(iconSizeClasses, activeColorClassName, "-my-4  shrink-0 text-oldgray-600")}
          {...iconLeftProps}
        />
      )}

      <div className={classNames("flex w-full min-w-0 items-center gap-8 truncate text-black", activeColorClassName)}>
        {children}
      </div>

      {IconRight && (
        <IconRight
          className={classNames(iconSizeClasses, activeColorClassName, "-my-4  shrink-0 text-oldgray-600")}
          {...iconRightProps}
        />
      )}
    </UnstyledButtonBase>
  );
});
