import { CodeEvaluatorRequest, HumanEvaluatorRequest, LLMEvaluatorRequest } from "@/types/app/evaluator";

export const DEFAULT_LLM_EVALUATOR: LLMEvaluatorRequest = {
  evaluator_type: "llm",
  return_type: "boolean",
  arguments_type: "target_free",
  prompt: {
    model: "gpt-4o",
    provider: "openai",
    endpoint: "chat",
    template: [
      {
        role: "system",
        content: "Examine whether the output below is grammatically correct.\n\n<output>\n{{ log.output }}\n</output>",
      },
    ],
    max_tokens: -1,
    temperature: 0.7,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    tools: [
      {
        name: "submit_judgment",
        description: "Submit a judgment on whether the output is grammatically correct.",
        parameters: {
          properties: {
            judgment: {
              type: "boolean",
              description: "The boolean judgment to submit.",
            },
            reasoning: {
              type: "string",
              description: "An explanation for the judgment.",
            },
          },
          required: ["judgment", "reasoning"],
          additionalProperties: false,
          type: "object",
        },
      },
    ],
  },
  options: [
    {
      name: "true",
      valence: "positive",
    },
    {
      name: "false",
      valence: "negative",
    },
  ],
};

// Used as a fallback default value in Editor. Was previously the default Evaluator. Should be similar to DEFAULT_LLM_EVALUATOR.
export const DEFAULT_LLM_COMPLETION_TEMPLATE: string = `State whether the [Output] below is grammatically correct. If it is not, state what is wrong. 
  
On a new line, give a final verdict of "True" or "False".

[Output]: {{ log.output }}`;

export const DEFAULT_PYTHON_EVALUATOR: CodeEvaluatorRequest = {
  evaluator_type: "python",
  arguments_type: "target_required",
  return_type: "boolean",
  code: 'def exact_match(log, testcase):\n    target = testcase["target"]["output"]\n    generation = log["output"]\n\n    return target == generation\n',
};

export const DEFAULT_HUMAN_EVALUATOR: HumanEvaluatorRequest = {
  evaluator_type: "human",
  return_type: "select",
  arguments_type: "target_free",
  instructions: "Rate the output on a scale of 1 (very bad) to 5 (very good).",
  options: [
    { name: "1", valence: "negative" },
    { name: "2", valence: "negative" },
    { name: "3", valence: "neutral" },
    { name: "4", valence: "positive" },
    { name: "5", valence: "positive" },
  ],
};
