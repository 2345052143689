import { ChevronRightIcon } from "@heroicons/react/solid";
import * as ContextMenuPrimative from "@radix-ui/react-context-menu";
import { ContextMenuContentProps } from "@radix-ui/react-context-menu";
import * as DropdownMenuPrimative from "@radix-ui/react-dropdown-menu";
import React, { forwardRef, MouseEventHandler } from "react";
import { ContextMenuListItem, ContextMenuListItemProps } from "./Lists/ContextMenuListItem";
import { classNames } from "./utils/classNames";

export const ContextMenu = (props: React.PropsWithChildren<{ className?: string }>) => {
  const { className, children } = props;

  return (
    <div className={classNames("relative inline-block", className)}>
      <ContextMenuPrimative.Root>{children}</ContextMenuPrimative.Root>
    </div>
  );
};

const ContextMenuItems = (
  props: ContextMenuContentProps &
    DropdownMenuPrimative.DropdownMenuContentProps & {
      isDropdownMenu?: boolean;
      isSubContent?: boolean;
    },
  ref: any,
) => {
  const { className, isDropdownMenu, isSubContent, ...rest } = props;
  const Items = isDropdownMenu
    ? isSubContent
      ? DropdownMenuPrimative.SubContent
      : DropdownMenuPrimative.Content
    : isSubContent
      ? ContextMenuPrimative.SubContent
      : ContextMenuPrimative.Content;
  return (
    <Items
      ref={ref}
      {...rest}
      className={classNames(
        "rounded-ms border border-oldgray-400 bg-white py-4 shadow-b data-state-open:animate-fade-in",
        isSubContent && "-my-4 ml-1",
        className,
      )}
    />
  );
};

export const ContextMenuItem = forwardRef<
  HTMLDivElement,
  ContextMenuListItemProps & {
    withinDropdownMenu?: boolean;
    triggerItem?: boolean;
    className?: string;
    href?: string;
    target?: string;
  }
>((props, ref) => {
  const { children, onClick, triggerItem = false, withinDropdownMenu = false, ...rest } = props;

  const Item = withinDropdownMenu
    ? triggerItem
      ? DropdownMenuPrimative.SubTrigger
      : DropdownMenuPrimative.Item
    : triggerItem
      ? ContextMenuPrimative.SubTrigger
      : ContextMenuPrimative.Item;

  return (
    <Item
      asChild
      ref={ref}
      disabled={rest.disabled}
      onClick={onClick as MouseEventHandler<HTMLButtonElement> & ((event: { preventDefault: Function }) => void)}
    >
      <ContextMenuListItem {...rest} IconRight={triggerItem ? ChevronRightIcon : rest.IconRight}>
        {children}
      </ContextMenuListItem>
    </Item>
  );
});
ContextMenuItem.displayName = "ContextMenuItem";

ContextMenu.Items = forwardRef(ContextMenuItems);
ContextMenu.Item = ContextMenuItem;
