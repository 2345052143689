import { PromptKernelRequest } from "@/types/app/prompt";

export const DEFAULT_PROMPT: PromptKernelRequest = {
  model: "gpt-4o",
  endpoint: "chat",
  template: [
    {
      role: "system",
      content: "You are a helpful assistant.",
    },
  ],
  provider: "openai",
  temperature: 1,
  max_tokens: -1,

  // Note that these 3 need to be provided, as otherwise the Prompt version,
  // when loaded in Editor and used to create new generations, will be saved
  // as a new Prompt version.
  // Specifically, our backend has a type hint on these fields as `float`,
  // but a default value of int (0 or 1 depending on the field). This means
  // that if the values are not provided, the backend will use the int values
  // that will differ from the values if those specific defaults are passed in.
  // (i.e. passing in "1" will yield a `1.0` float, but not passing in any value
  // will yield a `1` int.)
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
};
