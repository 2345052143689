import { cva, type VariantProps } from "cva";
import { forwardRef } from "react";

export type TagSize = 18 | 20 | 24;

const tagVariants = cva("flex items-center gap-4 truncate font-medium transition-colors", {
  variants: {
    size: {
      18: "h-[18px] rounded px-4 py-2 text-10-10",
      20: "h-20 rounded px-6 py-4 text-12-12",
      24: "h-24 rounded-[6px] px-6 py-[5px] text-14-14",
    },
    shade: {
      gray: "bg-gray-100 text-gray-950",
      violet: "bg-background-premium text-text-premium",
      sky: "bg-background-prompt-input text-text-prompt-input",
      red: "bg-red-100 text-red-950",
      green: "bg-green-100 text-green-950",
      yellow: "bg-yellow-100 text-yellow-950",
      orange: "bg-orange-100 text-orange-950",
      navy: "bg-oldnavy-200 text-oldnavy-800", // TODO: remove when we've redesigned how we show a model config
      blank: "border border-stroke-base-3 font-medium text-text-base-2",
    },
    interactive: {
      true: "cursor-pointer select-none ",
      false: "",
    },
  },
  compoundVariants: [
    // Add hover effects for each shade when interactive is true
    { interactive: true, shade: "gray", className: "hover:bg-gray-200" },
    { interactive: true, shade: "violet", className: "hover:bg-violet-200" },
    { interactive: true, shade: "sky", className: "hover:bg-sky-200" },
    { interactive: true, shade: "red", className: "hover:bg-red-200" },
    { interactive: true, shade: "green", className: "hover:bg-green-150" },
    { interactive: true, shade: "yellow", className: "hover:bg-yellow-200" },
    { interactive: true, shade: "orange", className: "hover:bg-orange-200" },
    { interactive: true, shade: "navy", className: "hover:bg-oldnavy-300" },
    { interactive: true, shade: "blank", className: "hover:bg-gray-50 hover:border-stroke-base-5" },
  ],
  defaultVariants: {
    size: 20,
    shade: "gray",
    interactive: false,
  },
});

export interface TagProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof tagVariants> {
  children: React.ReactNode;
}

// Why does this exist? We have Tag v1.
export const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ size, shade, className, children, interactive, ...props }: TagProps, ref) => {
    return (
      // It doesn't truncate when hovered
      <div className="flex [&:not(:hover)]:truncate" ref={ref}>
        <div {...props} className={tagVariants({ size, shade, interactive, className })}>
          {children}
        </div>
      </div>
    );
  },
);

Tag.displayName = "Tag";

export const ErrorTag = ({ size = 20, children, ...props }: Omit<TagProps, "shade">) => (
  <Tag size={size} shade="red" {...props}>
    {children}
  </Tag>
);

export default Tag;
