import { getAuthToken } from "@/lib/use-auth";
import { Page } from "@/types/generic";
import { AxiosPromise, AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import useSWR, { SWRConfiguration } from "swr";
import { ApiService } from "./api.service";
import { FilterModel, addSortAndFilterURLSearchParams } from "@components/llama/Tables/utils";
import { VersionStatus } from "@/types/app/version";
import { Log, LogResponse, parseLogResponse } from "@/types/app/log";
import { SearchType } from "@components/llama/Tables/TableSearchTypeDropdown";

export interface LogDataSnapshot {
  id: string;
  name: string;
  count: number;
}

export interface VariablesWithValues {
  [key: string]: string | number;
}
interface PaginatedLogsProps {
  fileId: string;
  size?: number;
  page?: number;
  search?: string;
  searchType?: SearchType;
  metadataSearch?: string;
  versionsFilter?: VersionStatus | null;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  filterState?: FilterModel;
}

export type UseLogsProps = { autoRefresh?: boolean } & PaginatedLogsProps;

/**
 * Fetches logs for a file.
 *
 * This is used in the data tables where whether the data is
 * refreshed is controlled by the user because the table will otherwise
 * jump around.
 *
 * To prevent any foolish developer casually using this hook without considering
 * the revalidation logic, we have an autoRefresh option which is true by default.
 * If this is false, the hook will use a separate SWR cache key so that it is not
 * affected by revalidations elsewhere.
 */
export const useLogs = (props?: UseLogsProps, swrOptions: SWRConfiguration<Page<LogResponse>> = {}) => {
  const {
    fileId,
    size = 10,
    page = 1,
    search = undefined,
    searchType = undefined,
    versionsFilter = null,
    startDate = undefined,
    endDate = undefined,
    filterState = undefined,
    autoRefresh = true,
  } = props || {};

  let url: string | null = null;
  if (props) {
    const baseParams = new URLSearchParams({
      // Using `props.fileId` as TypeScript would complain about `fileId` being undefined.
      file_id: props.fileId.toString(),
      page: page.toString(),
      size: size.toString(),
    });
    const urlParams = addSortAndFilterURLSearchParams(baseParams, {
      search,
      searchType,
      versionsFilter,
      startDate: startDate ?? undefined,
      endDate: endDate ?? undefined,
      filterModel: filterState,
    });
    url = `/v5/logs?${urlParams.toString()}${autoRefresh ? "" : "&no-refresh"}`;
  }
  const { data, error, mutate, isValidating } = useSWR<Page<LogResponse>>(url ? [url, getAuthToken()] : null, {
    revalidateOnFocus: autoRefresh,
    revalidateOnReconnect: autoRefresh,
    revalidateIfStale: autoRefresh,
    ...swrOptions,
  });

  const pagedLogs = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return {
      ...data,
      records: data.records.map((log) => parseLogResponse(log)),
    };
  }, [data]);

  return { pagedLogs, error, loading: !data && !error, mutate, isValidating };
};

/**
 * Fetches logs by their IDs.
 * * */
export const getLog = async (id: string): Promise<Log> => {
  const response = (await ApiService.get(`/v5/logs/${id}`)) as AxiosResponse<LogResponse>;
  return parseLogResponse(response.data);
};

export const useLog = (id: string | null, swrOptions: SWRConfiguration<LogResponse> = {}) => {
  const { data, error, mutate } = useSWR<LogResponse>(id ? [`/v5/logs/${id}`, getAuthToken()] : null, swrOptions);
  return { log: data && parseLogResponse(data), error, loading: !data && !error, mutate };
};

export const deleteLogs = (ids: string[]): AxiosPromise<void> => {
  const params = new URLSearchParams(ids.map((id) => ["id", id]));
  return ApiService.remove(`/v5/logs?${params.toString()}`);
};

export const exportRaw = (params: URLSearchParams) => {
  return ApiService.post(`/v4/logs/export-raw?${params.toString()}`, {}, { transformResponse: (r) => r });
};

// We think these are mainly used for getting filter query params. ag-grid's filter state uses
// these column ids and that filter state can be converted to query params for filtering.
// It does also work to prevent column ids from clashing.
export const EVALUATOR_COLUMN_ID_PREFIX = "evaluator-";
export const FILTER_FOR_MISSING_VALUE_QUERY_PARAM_VALUE = "__missing_value";

interface DatapointsCategoricalColumnOptions {
  source: string[];
  model: string[];
  version_id: string[];
  data_snapshot: string[];
  user: string[];
  judgment?: string[];

  // Add this line for evaluator column IDs
  [key: `${typeof EVALUATOR_COLUMN_ID_PREFIX}${string}`]: string[];
}

export const useDatapointsCategoricalColumnOptions = (
  projectId?: string,
  swrOptions: SWRConfiguration<DatapointsCategoricalColumnOptions> = {},
) => {
  const { data, error, mutate } = useSWR<DatapointsCategoricalColumnOptions>(
    [`/datapoints-categorical-column-options?project_id=${projectId}`, getAuthToken()],
    swrOptions,
  );
  return { columnOptions: data, error: error, loading: !data && !error, mutate };
};

export interface DatapointsFilterParams {
  source_filter: string[];
  // model_filter: string[];
  version_id_filter: string[];
  user_filter: string[];
  feedback_filters: Record<string, (string | null)[]>; // {group_name: filter_values}. `None` indicates that missing values are allowed.
  search: string | null;
}
